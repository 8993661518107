const en_US = {
  "menu.home": "home",
  "menu.category": "category",
  "menu.car": "cart",
  "menu.my": "mine",
  "page.home.recommend": "recommend",
  "page.home.new": "new",
  "page.home.sale": "sales",
  "page.category.sales": "sales",
  "page.category.price": "price",
  "page.category.comments_count": "comments",
  "page.goodinfo.detail": "detail",
  "page.goodinfo.comment": "comment",
  "page.goodinfo.related": "related",
  "page.orderlist.all": "全部",
  "page.orderlist.tobepaid": "to-be-paid",
  "page.orderlist.paid": "paid",
  "page.orderlist.delivered": "delivered",
  "page.orderlist.finish": "finish",
  "page.orderlist.overdue": "overdue",
};
export default en_US;
